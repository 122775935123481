import { IBackButton, INavigationDrawerStep } from '../models/navigationDrawer'
import { ReactComponent as ProgramInfoIcon } from '../assets/images/programInfo.svg'
import { ReactComponent as LearningsIcon } from '../assets/images/learnings.svg'
import { ReactComponent as WaitListIcon } from '../assets/images/waitList.svg'
import { ITabNavigationOptions } from '../models/tabNavigation'

export const STEPS = ['Program Info', 'Payers', 'Strategy']
export const PROGRAM_NAV_STEPS: INavigationDrawerStep[] = [
  {
    Icon: ProgramInfoIcon,
    text: 'Program Details',
    linkStart: '/programs',
    linkEnd: 'details'
  },
  {
    Icon: WaitListIcon,
    text: 'Wait List',
    linkStart: '/programs',
    linkEnd: 'wait-list'
  },
  {
    Icon: LearningsIcon,
    text: 'Literacy',
    linkStart: '/programs',
    linkEnd: 'literacy'
  },
  {
    Icon: LearningsIcon,
    text: 'Engagements',
    linkStart: '/programs',
    linkEnd: 'engagements'
  }
]

export const PROGRAM_BACK_BUTTON: IBackButton = {
  text: 'Back to Programs',
  route: '/programs'
}

export const PROGRAM_PAYERS_TAB_OPTIONS: ITabNavigationOptions[] = [
  {
    label: 'OVERVIEW',
    type: 'programPayer'
  },
  {
    label: 'PAYER DETAILS',
    type: 'programPayer'
  }
]

export const PROGRAM_STRATEGY_TAB_OPTIONS: ITabNavigationOptions[] = [
  {
    label: 'OVERVIEW',
    type: 'programStrategy'
  },
  {
    label: 'STRATEGY DETAILS',
    type: 'programStrategy'
  }
]

export const PROGRAM_LITERACY_TAB_OPTIONS: ITabNavigationOptions[] = [
  {
    label: 'OVERVIEW',
    type: 'programLiteracy'
  },
  {
    label: 'LEARNING MODULES',
    type: 'programLiteracy'
  }
]

export const TRANSACTION_TYPE = [
  { value: 'Debit', label: 'Debit' },
  { value: 'Credit', label: 'Credit' }
]

export const ACTIVITY_TYPE = [
  { value: 'Adherence', label: 'Adherence' },
  { value: 'Learning', label: 'Learning' },
  { value: 'Survey', label: 'Survey' },
  { value: 'Refill', label: 'Refill' },
  { value: 'Bonus', label: 'Bonus' },
  { value: 'Correction', label: 'Correction' }
]

export const ADHERENCE_HEADINGS = [
  'adherencePointsPortion',
  'adherenceContribution',
  '1stLate',
  '2ndLate',
  '3rdLate',
  'skippedDose',
  'lateDefinition'
]

export const STRATEGY_SUB_HEADINGS = [
  'weight',
  'points',
  'literacyContribution',
  'surveyAvailableQuantity',
  'surveyMaximumQuantity',
  'bonusAvailableQuantity',
  'bonusMaximumQuantity'
]

export const PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
  'NU',
  'NT'
]

import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`

interface IItemProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  title: string
}

const Item = React.forwardRef<HTMLDivElement, IItemProps>(
  ({ title, ...props }, ref) => {
    /**
     * ----- Render -----
     */
    return (
      <Card ref={ref} {...props}>
        <b>{title}</b>
      </Card>
    )
  }
)

export default Item

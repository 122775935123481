import { useDroppable } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { IModule } from 'src/models/module'
import styled from 'styled-components'
import ModuleItem from './ModuleItem'

const Container = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
`

interface IAllModulesList {
  modules: IModule[]
}

const AllModulesList = ({ modules }: IAllModulesList) => {
  /**
   * ----- Hook Initialization -----
   */

  const { isOver, setNodeRef } = useDroppable({
    id: 'removal'
  })

  /**
   * ----- Variables -----
   */

  const style = {
    color: isOver ? 'red' : undefined
  }

  /**
   * ----- Render -----
   */

  return (
    <SortableContext items={modules.map((module) => module.moduleId)}>
      <Container ref={setNodeRef} style={style}>
        <h4>All Modules</h4>
        {modules.map((module) => (
          <ModuleItem
            key={module.moduleId}
            module={{
              moduleId: module.moduleId,
              title:
                module.title.en || module.title.fr || 'Could not find language'
            }}
          />
        ))}
      </Container>
    </SortableContext>
  )
}

export default AllModulesList

import React from 'react'

import { Text, Layout } from 'src/shared/styled'
import { IProgram } from 'src/models/program'
import { IEngagement } from 'src/models/module'
import styled from 'styled-components'
import {
  useGetModules,
  useUpdateEngagementsForProgram
} from 'src/shared/hooks/modules'
import EngagementList from 'src/components/Engagement/EngagementList'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent
} from '@dnd-kit/core'
import AllModulesList from 'src/components/Engagement/AllModulesList'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Item from 'src/components/Engagement/Item'
import { toast } from 'react-toastify'

const Container = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const ContentContainer = styled.div`
  width: 45%;
`

const AddEngagement = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
`

interface IEngagementsProps {
  engagements: IEngagement[]
  program: IProgram
}

const { DetailsContainer, DetailsHeader, DetailsHeaderContainer } = Layout
const { HeaderName, SubHeaderName } = Text

const Engagements: React.FC<IEngagementsProps> = ({ program, engagements }) => {
  const { name: programName } = program

  /**
   * ----- Hook Initialization -----
   */

  const [engagementState, setEngagementState] =
    React.useState<IEngagement[]>(engagements)

  const [activeTitle, setActiveTitle] = React.useState<string | null>(null)

  const { modules: allModules } = useGetModules()

  const { isLoading, updateEngagementsForProgram } =
    useUpdateEngagementsForProgram(program.id, {
      onSuccess: () => {
        toast.success('Engagements updated successfully')
      },
      onError: (error) => {
        toast.error(`Error updating engagements: ${error.message}`)
      }
    })

  /**
   * ----- Variables -----
   */

  const filteredModules = React.useMemo(() => {
    return allModules?.filter((module) => {
      return !engagementState.some((engagement) => {
        return engagement.modules.some(
          (engagementModule) => engagementModule.moduleId === module.moduleId
        )
      })
    })
  }, [allModules, engagementState])

  /**
   * ----- Functions -----
   */

  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event
    if (!over) return
    if (!active) return

    const engagementStateCopy = [...engagementState]

    const module = active.data.current?.module
    if (!module) return

    const removeFromEngagement = () => {
      // Remove the module from the previous location
      const currentLocation = active.data.current?.currentLocation as number
      if (
        currentLocation !== undefined &&
        engagementStateCopy[currentLocation]
      ) {
        // Remove the module from the previous location
        engagementState[currentLocation].modules = engagementStateCopy[
          currentLocation
        ].modules.filter((module) => module.moduleId !== active.id)
      }
    }

    if (engagementState[over.id as number]) {
      // If the module is being dragged to an engagement

      removeFromEngagement()

      // Add the module to the new location
      engagementStateCopy[over.id as number].modules.push(module)
    } else if (over.id === 'removal') {
      // Module being dragged to the removal area
      removeFromEngagement()
    } else if (over.data.current?.module) {
      // Module is being sorted
      const { currentLocation } = over.data.current
      if (currentLocation !== undefined) {
        // Find the index of the module being sorted
        const index = engagementStateCopy[currentLocation].modules.findIndex(
          (module) => module.moduleId === active.id
        )

        // Add the module to the new location
        if (index !== -1) {
          // Find the index of the module it is being sorted before
          const newIndex = engagementStateCopy[
            currentLocation
          ].modules.findIndex((module) => module.moduleId === over.id)

          // Remove the module from the previous location
          engagementStateCopy[currentLocation].modules.splice(index, 1)

          // Add the module to the new location
          engagementStateCopy[currentLocation].modules.splice(
            newIndex,
            0,
            module
          )
        } else {
          engagementStateCopy[currentLocation].modules.push(module)
        }
      } else {
        // Module is being dragged to the removal area
        removeFromEngagement()
      }
    }

    setEngagementState(engagementStateCopy)
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event

    setActiveTitle(active.data.current?.module?.title || null)
  }

  const handleSubmit = () => {
    updateEngagementsForProgram(
      engagementState.map((engagement) => {
        return {
          ...engagement,
          modules: engagement.modules.map((module) => module.moduleId)
        }
      })
    )
  }

  /**
   * ----- Render -----
   */

  return (
    <DetailsContainer>
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <DetailsHeaderContainer>
          <DetailsHeader>
            <div>
              <HeaderName>{programName}</HeaderName>
              <SubHeaderName>Engagements</SubHeaderName>
            </div>
            <ButtonWithWidth isLoading={isLoading} onClick={handleSubmit}>
              SAVE
            </ButtonWithWidth>
          </DetailsHeader>
        </DetailsHeaderContainer>
        <Container>
          <ContentContainer>
            {engagementState.map((engagement, index) => (
              <EngagementList
                key={index}
                engagement={engagement}
                index={index}
                isLast={index === engagementState.length - 1}
                onDaysChange={(days) => {
                  const engagementStateCopy = [...engagementState]
                  engagementStateCopy[index].daysUntilNext = days
                  setEngagementState(engagementStateCopy)
                }}
                onDelete={() => {
                  const engagementStateCopy = [...engagementState]
                  engagementStateCopy.splice(index, 1)
                  setEngagementState(engagementStateCopy)
                }}
                onFundsChange={(funds) => {
                  const engagementStateCopy = [...engagementState]
                  engagementStateCopy[index].funds.amount = funds
                  setEngagementState(engagementStateCopy)
                }}
              />
            ))}
            <AddEngagement
              onClick={() => {
                setEngagementState([
                  ...engagementState,
                  {
                    funds: { amount: 0, currencyCode: 'CAD' },
                    daysUntilNext: 0,
                    modules: []
                  }
                ])
              }}
            >
              <h2>Add Engagement</h2>
            </AddEngagement>
          </ContentContainer>
          <ContentContainer>
            {filteredModules ? (
              <AllModulesList modules={filteredModules} />
            ) : (
              <LoadingScreen />
            )}
          </ContentContainer>
        </Container>
        <DragOverlay>
          {activeTitle ? <Item title={activeTitle} /> : null}
        </DragOverlay>
      </DndContext>
    </DetailsContainer>
  )
}

export default Engagements

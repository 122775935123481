import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IModuleEssential } from 'src/models/module'
import Item from './Item'

interface IModuleItemProps {
  module: IModuleEssential
  engagementIndex?: number
}

const ModuleItem = ({ module, engagementIndex }: IModuleItemProps) => {
  /**
   * ----- Hook Initialization -----
   */

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: module.moduleId,
    data: { currentLocation: engagementIndex, module }
  })

  /**
   * ----- Variables -----
   */

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  /**
   * ----- Render -----
   */
  return (
    <Item
      title={module.title}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    />
  )
}

export default ModuleItem
